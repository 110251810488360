.event-page .form-control {
    color: #fff !important;
}
.col-md-4.calendar-box.form-group {
    padding-top: 15px;
}
i.mdi.mdi-calendar {
    cursor: pointer;
}
.open-event-details{
    color: #203961;
    cursor: pointer;
}